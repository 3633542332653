import {
    FETCH_AUDIT,
    FETCH_DETAILED_AUDIT,
    FETCH_DETAILED_AUDIT_SUCCESS,
    DOWNLOAD_CSV,
    DELETE_AUDIT_RANGE,
    DELETE_SESSION_RANGE,
    FINISH_DOWNLOAD_CSV,
    FETCH_AUDIT_SUCCESS,
    SET_AUDIT_FILTERED_TYPES,
    SET_AUDIT_FILTERED_ACCESSES,
    FINISH_FETCH_AUDIT,
} from '../actionsTypes/audit.actionTypes';
import { AuditDto, DetailedAuditDTO } from '../serverapi/api';

export interface IAuditFetchParams {
    start?: number;
    end?: number;
    search?: string;
    actions?: string;
    ts?: boolean;
    pd?: boolean;
}

export interface IFetchAudit {
    type: typeof FETCH_AUDIT;
    params: IAuditFetchParams;
    serverId: string;
}

export interface IDeleteAuditRange {
    type: typeof DELETE_AUDIT_RANGE;
    payload: { start: number; end: number; serverId: string; password: string };
}

export type TDownloadCSVAction = {
    type: typeof DOWNLOAD_CSV;
    payload: {
        serverId: string;
        params: IAuditFetchParams;
    };
};

export type TFinishDownloadCSVAction = {
    type: typeof FINISH_DOWNLOAD_CSV;
};

export type TFinishFetchAuditAction = {
    type: typeof FINISH_FETCH_AUDIT;
};

export interface IFetchDetailedAudit {
    type: typeof FETCH_DETAILED_AUDIT;
    payload: { serverId: string; id: number };
}

export interface IFetchDetailedAuditSuccess {
    type: typeof FETCH_DETAILED_AUDIT_SUCCESS;
    payload: { serverId: string; detailedAudit: DetailedAuditDTO };
}

export interface IFetchAuditSuccess {
    type: typeof FETCH_AUDIT_SUCCESS;
    serverId: string;
    audit: AuditDto[];
}

export interface IDeleteSessionRange {
    type: typeof DELETE_SESSION_RANGE;
    payload: { start: number; end: number; serverId: string; password: string };
}

export interface IFilteredTypes {
    type: typeof SET_AUDIT_FILTERED_TYPES;
    payload: { filteredTypes: string[] };
}

export interface IFilteredAccesses {
    type: typeof SET_AUDIT_FILTERED_ACCESSES;
    payload: { filteredAccesses: accessTypes[] };
}

export type TAuditActions =
    | IFetchAuditSuccess
    | IFetchAudit
    | IFetchDetailedAuditSuccess
    | TDownloadCSVAction
    | TFinishDownloadCSVAction
    | TFinishFetchAuditAction
    | IFilteredTypes
    | IFilteredAccesses;

export enum AuditActions {
    USER_TRY_AUTH = 'USER_TRY_AUTH',
    USER_SUCCESS_AUTH = 'USER_SUCCESS_AUTH',
    USER_FAIL_AUTH = 'USER_FAIL_AUTH',
    WRITE_OBJECT_ACL = 'WRITE_OBJECT_ACL',
    READ_TREE_NODE = 'READ_TREE_NODE',
    DELETE_TREE_NODE = 'DELETE_TREE_NODE',

    SAVE_TREE_NODE = 'SAVE_TREE_NODE',
    CHANGE_TREE_NODE_PROPERTIES = 'CHANGE_TREE_NODE_PROPERTIES',
    CREATE_TREE_NODE = 'CREATE_TREE_NODE',
    RESTORE_TREE_NODE = 'RESTORE_TREE_NODE',
    RESTORE_NODE = 'RESTORE_NODE',
    LOCK_TREE_NODE = 'LOCK_TREE_NODE',
    UNLOCK_TREE_NODE = 'UNLOCK_TREE_NODE',
    READ_TREE_NODE_VERSION = 'READ_TREE_NODE_VERSION',
    READ_TREE_NODE_VERSIONS = 'READ_TREE_NODE_VERSIONS',
    MOVE_TREE_NODE = 'MOVE_TREE_NODE',
    RENAME_TREE_NODE = 'RENAME_TREE_NODE',
    IMPORT_PRESET = 'IMPORT_PRESET',
    IMPORT_PRESET_ELEMENTS = 'IMPORT_PRESET_ELEMENTS',
    DELETE_PRINCIPAL = 'DELETE_PRINCIPAL',
    CHANGE_PRINCIPAL = 'CHANGE_PRINCIPAL',
    CHANGE_PRINCIPAL_GROUP = 'CHANGE_PRINCIPAL_GROUP',
    CHANGE_PRINCIPAL_LICENSE = 'CHANGE_PRINCIPAL_LICENSE',
    CHANGE_PRINCIPAL_ACCESS = 'CHANGE_PRINCIPAL_ACCESS',
    CREATE_PRINCIPAL = 'CREATE_PRINCIPAL',
    READ_TREE_NODES_BY_PARENT = 'READ_TREE_NODES_BY_PARENT',
}

export enum AuditActionsWithoutDetails {
    READ_TREE_NODE = 'READ_TREE_NODE',
    DELETE_TREE_NODE = 'DELETE_TREE_NODE',
    SAVE_TREE_NODE = 'SAVE_TREE_NODE',
    CHANGE_TREE_NODE_PROPERTIES = 'CHANGE_TREE_NODE_PROPERTIES',
    CREATE_TREE_NODE = 'CREATE_TREE_NODE',
    RESTORE_NODE = 'RESTORE_NODE',
    LOCK_TREE_NODE = 'LOCK_TREE_NODE',
    UNLOCK_TREE_NODE = 'UNLOCK_TREE_NODE',
    READ_TREE_NODE_VERSION = 'READ_TREE_NODE_VERSION',
    RENAME_TREE_NODE = 'RENAME_TREE_NODE',
    CHANGE_PRINCIPAL = 'CHANGE_PRINCIPAL',
    READ_TREE_NODES_BY_PARENT = 'READ_TREE_NODES_BY_PARENT',
}

export enum AccessModel {
    DENY,
    INHERIT,
    ALLOW,
}

export const actionTypesKeys: string[] = [
    'AIP_SEARCH',
    'AIP_SEARCH_HISTORY',
    'APPROVAL_ADD_ASSISTANT',
    'READ_TREE_NODE',
    'READ_TREE_NODES',
    'MOVE_TREE_NODE',
    'DELETE_TREE_NODE',
    'CLONE_TREE_NODE',
    'SAVE_TREE_NODE',
    'RENAME_TREE_NODE',
    'CHANGE_TREE_NODE_PROPERTIES',
    'CREATE_TREE_NODE',
    'RESTORE_TREE_NODE',
    'RESTORE_NODE',
    'LOCK_TREE_NODE',
    'UNLOCK_TREE_NODE',
    'USER_LOCK_TREE_NODE',
    'USER_UNLOCK_TREE_NODE',
    'READ_TREE_NODE_VERSION',
    'READ_TREE_NODE_VERSIONS',
    'SAVE_TREE_NODE_VERSION',
    'READ_MODEL_ASSIGNMENT',
    'RUN_SCRIPT',
    'READ_SCRIPT_EXECUTION_RESULT',
    'SAVE_SCRIPT_EXECUTION',
    'PARSE_SCRIPT_PARAMETERS',
    'RUN_SCRIPT_SCHEDULER',
    'SCRIPT_LOCK_TREE_NODE',
    'SCRIPT_UNLOCK_TREE_NODE',
    'PRINT_NODE',
    'READ_MODEL_IMAGE',
    'READ_MODEL_BY_NODE',
    'READ_TREE_NODES_BY_PARENT',
    'MODEL_CRC_ERROR',
    'DELETE_MODEL_DECOMPOSITION',
    'READ_TREE_ROOT_NODES',
    'COMMENT_ADDITIONAL_STATUS',
    'COMMENT_SAVE',
    'COMMENT_CREATE',
    'COMMENT_PIN',
    'COMMENT_UNPIN',
    'COMMENT_STATUS',
    'COMPARE_ELEMENTS',
    'READ_COMMENT',
    'READ_COMMENT_LIST',
    'COMMENT_DELETE',
    'DOWNLOAD_FILE',
    'UPLOAD_FILE',
    'DELETE_FILE',
    'EXECUTE_SIMULATION',
    'READ_SIMULATION_RUN',
    'DELETE_SIMULATION_RUN',
    'SAVE_SIMULATION_RUN',
    'USER_TRY_AUTH',
    'USER_SUCCESS_AUTH',
    'USER_FAIL_AUTH',
    'USER_BLOCKED_FAIL_AUTH',
    'USER_AUTH_TMP_BLOCKED',
    'LOGOUT_USER',
    'SOME_USER_LOGOUT',
    'READ_USER_SESSION_HISTORY',
    'READ_ALL_USERS_SESSION_HISTORY',
    'READ_ALL_LICENSES',
    'READ_LICENSE',
    'ADD_LICENSE',
    'DELETE_LICENSE',
    'READ_RESULTING_LICENSE',
    'READ_USED_LICENSE',
    'READ_OBJECT_ACL',
    'WRITE_OBJECT_ACL',
    'IMPORT',
    'REPOSITORY_IMPORT',
    'EXPORT',
    'CREATE_PRINCIPAL',
    'DELETE_PRINCIPAL',
    'CHANGE_PRINCIPAL',
    'CHANGE_USER_PASSWORD',
    'READ_PRINCIPAL',
    'READ_PRINCIPAL_LIST',
    'READ_ALL_PRINCIPALS',
    'CHANGE_PRINCIPAL_GROUP',
    'CHANGE_PRINCIPAL_LICENSE',
    'CHANGE_PRINCIPAL_ACCESS',
    'CHANGE_PRINCIPAL_PROFILE',
    'READ_USER_ACCESSIBLE_REPOSITORIES',
    'CREATE_USER_FOLDER',
    'LICENSE_AGREEMENT_CONFIRMED',
    'WRITE_SYSTEM_PROPERTY',
    'READ_ALL_SYSTEM_PROPERTIES',
    'CHECK_SCRIPT_ENGINE',
    'CHECK_IMAGE_SERVICE',
    'READ_SYSTEM_STATUS',
    'SERVER_IS_STARTED',
    'SEARCH',
    'SEARCH_SEVERAL',
    'AUDIT_SEARCH',
    'AUDIT_DELETE_RANGE',
    'SESSION_DELETE_RANGE',
    'READ_PRESET',
    'READ_ALL_PRESETS',
    'CREATE_PRESET',
    'DELETE_PRESET',
    'CHANGE_PRESET',
    'IMPORT_PRESET',
    'IMPORT_PRESET_ELEMENTS',
    'EXPORT_PRESET',
    'CREATE_SERVER_PROFILE',
    'EDIT_SERVER_PROFILE',
    'DELETE_SERVER_PROFILE',
    'CREATE_SCHEDULER_TASK',
    'EDIT_SCHEDULER_TASK',
    'DELETE_SCHEDULER_TASK',
    'CREATE_EVENT',
    'READ_EVENTS',
    'UPDATE_EVENT',
    'DELETE_EVENT',
    'LICENSE_AGREEMENT_DELETE',
    'OPERATION_DOWNLOAD_LOG',
    'OPERATION_SEARCH_FULL',
    'REPLACE_TREE_NOTATIONS',
    'CLOSE_SESSION',
    'CREATE_EXTERNAL_USER_SESSION',
    'SET_SHOW_DELETED_OBJECT',
    'READ_SHOW_DELETED_OBJECT',
    'SAVE_FAVORITE',
    'DELETE_FAVORITE',
    'DELETE_MESSAGE',
    'CLEAN_MESSAGES',
    'READ_MESSAGES',
    'CANCEL_APPROVAL',
    'COMMENT_CHANGE',
    'COPY_APPROVAL',
    'CREATE_APPROVAL',
    'DELETE_APPROVAL',
    'ERASE_TREE_NODE',
    'READ_APPROVALS',
    'RUN_APPROVAL',
    'SAVE_TREE_NODES',
    'SEND_EMAIL',
    'UPDATE_APPROVAL',
    'VOTE_EVENT',
    'COMMENT_APPROVAL',
    'CHANGE_STATUS_APPROVAL',
    'CHANGE_STATUS_APPROVAL_STAGE',
    'CREATE_SUBSCRIPTION',
    'DELETE_SUBSCRIPTION',
    'READ_NOTIFICATION',
    'READ_NOTIFICATIONS',
    'DELETE_NOTIFICATION',
    'SAVE_NOTIFICATION',
    'CREATE_NOTIFICATION',
    'READ_NOTIFICATION_TEMPLATE',
    'READ_NOTIFICATIONS_TEMPLATES',
    'DELETE_NOTIFICATION_TEMPLATE',
    'SAVE_NOTIFICATION_TEMPLATE',
    'CREATE_NOTIFICATION_TEMPLATE',
    'CREATE_APPROVAL_TEMPLATE',
    'DELETE_APPROVAL_TEMPLATE',
    'READ_APPROVAL_TEMPLATE',
    'READ_APPROVAL_TEMPLATES',
    'UPDATE_APPROVAL_TEMPLATE',
    'CREATE_PUBLICATION',
    'DELETE_PUBLICATION',
    'READ_PUBLIC_NODE_LIST',
    'READ_PUBLIC_TREE_NODE',
    'SAVE_AIP_ALIAS',
    'DELETE_AIP_ALIAS',
    'FIND_DUPLICATE_TREE_NODE',
    'MERGE_TREE_NODE',
    'OPERATION_CREATE',
    'OPERATION_FINISH',
    'OPERATION_UPDATE',
    'READ_NODE_STATS',
    'READ_PERSONAL_PROFILE',
];

export enum accessTypes {
    tradeSecret = 'tradeSecret',
    personalData = 'personalData',
}
