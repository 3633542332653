export const MATRIX_PAST_OBJECTS = 'MATRIX_PAST_OBJECTS';

export const MATRIX_PAST_EMPTY_LANE = 'MATRIX_PAST_EMPTY_LANE';

export const MATRIX_COPY_LANES = 'MATRIX_COPY_LANES';

export const MATRIX_SELECT_HEADER_CELLS = 'MATRIX_SELECT_HEADER_CELLS';

export const MATRIX_SELECT_CELLS = 'MATRIX_SELECT_CELLS';

export const MATRIX_CLEAR_SELECTED_CELLS = 'MATRIX_CLEAR_SELECTED_CELLS';

export const MATRIX_GET_EDGES_LIST = 'MATRIX_GET_EDGES_LIST';

export const MATRIX_START_LOADING_CELL_DATA = 'MATRIX_START_LOADING_CELL_DATA';

export const MATRIX_ADD_CELL_DATA = 'MATRIX_ADD_CELL_DATA';

export const MATRIX_DELETE_EDGE_DEFINITION = 'MATRIX_DELETE_EDGE_DEFINITION';

export const MATRIX_HANDLE_DECOMPOSITION_CLICK = 'MATRIX_HANDLE_DECOMPOSITION_CLICK';

export const MATRIX_DELETE_OBJECT_HEADERS = 'MATRIX_DELETE_OBJECT_HEADERS';
