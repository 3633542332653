import type { GridCell, Symbol } from '../../../../serverapi/api';
import { MxCell } from 'MxGraph';
import { createGridCellLayout } from '../../grid/GridCellLayout';
import { GridDiagram } from '../../grid/GridDiagram';
import { LocalesService } from '@/services/LocalesService';
import { groupBy, filter, uniqBy } from 'lodash-es';
import messages from './PSDGrid.messages';
import {
    contentAvailableSymbols,
    firstColumnAvailableSymbols,
    firstRowAvailableSymbols,
    PSDEdgeTypeId,
} from './PSDGridInitial.config';
import { ShapeStyle } from '@/models/Symbols.constants';

class PSDGrid extends GridDiagram {
    isRenderEdges = true;
    invisibleEdges = true;
    public isGridValidTarget({ target, source, symbolId }): boolean {
        const { symbolId: sourceSymbolId } = source?.getValue() || {};
        const actualSymbolId = (symbolId || sourceSymbolId || '').trim();
        if (Object.values(ShapeStyle).includes(actualSymbolId as ShapeStyle)) {
            return true;
        }

        const { gridRowPosition, gridColumnPosition } = this.getCellGridPosition(target);

        if (gridColumnPosition === 1 && gridRowPosition === 1) {
            return false;
        }
        if (gridColumnPosition === 1 && firstColumnAvailableSymbols.includes(actualSymbolId)) {
            return true;
        }

        if (gridRowPosition === 1 && firstRowAvailableSymbols.includes(actualSymbolId)) {
            return true;
        }

        if (
            Number(gridRowPosition) > 1 &&
            Number(gridColumnPosition) > 1 &&
            contentAvailableSymbols.includes(actualSymbolId)
        ) {
            return true;
        }

        return super.isGridValidTarget({ target, source, symbolId: actualSymbolId });
    }

    getAvailableToChangeSymbolTypeType(symbol: MxCell, symbols: Symbol[]): Symbol[] {
        const { symbolId } = symbol?.getValue() || {};

        const filterAvailableSymbols = (id: string[]) => {
            return symbols.filter((symbol) => id.includes(symbol.id));
        };

        if (firstColumnAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstColumnAvailableSymbols);
        }

        if (firstRowAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstRowAvailableSymbols);
        }

        if (contentAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(contentAvailableSymbols);
        }

        return [];
    }

    getAvailableToReplace(symbol: MxCell, symbols: Symbol[]) {
        const { symbolId } = symbol?.getValue() || {};

        const filterAvailableSymbols = (id: string[]) => {
            return symbols.filter((symbol) => id.includes(symbol.id));
        };

        if (firstColumnAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstColumnAvailableSymbols);
        }

        if (firstRowAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstRowAvailableSymbols);
        }

        if (contentAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(contentAvailableSymbols);
        }

        return [];
    }

    private getCellGridPosition(cell: MxCell): {
        gridRowPosition: number | undefined;
        gridColumnPosition: number | undefined;
    } {
        try {
            const gridColumnPosition = this.graph.indexOfColumn(cell);
            const gridRowPosition = this.graph.indexOfRow(cell);

            return { gridRowPosition, gridColumnPosition };
        } catch (e) {
            return { gridRowPosition: undefined, gridColumnPosition: undefined };
        }
    }

    protected addLayout() {
        createGridCellLayout(this.graph);
    }

    public renderEdges(source: MxCell) {
        try {
            if (!this.isRenderEdges) {
                return;
            }

            const { cells, rows, columns } = this.serialize();
            const firstRowId = rows[0]?.id;
            const firstColumnId = columns[0]?.id;

            const model = this.graph.getModel();

            const getPositionByCell = (tableCell: MxCell): GridCell | undefined => {
                const id = tableCell.getId();

                return cells.find((c) => {
                    return c.id === id;
                });
            };

            const modelObjects: MxCell[] = filter(model.cells, (cell: MxCell) => {
                return this.isEdgeSupportedCell(cell);
            });

            const filledTableCells: (GridCell | undefined)[] = modelObjects.map((c) => {
                return getPositionByCell(c.getParent());
            });

            if (!filledTableCells.length) {
                return;
            }

            const filledTableCellsWithotDub = uniqBy(filledTableCells, c => c?.id);

            const byRowId = groupBy(filledTableCellsWithotDub, 'rowId');
            const byColumnId = groupBy(filledTableCellsWithotDub, 'columnId');

            const [sourceCell] = source?.getStyle().includes('shape=partialRectangle')
                ? (source.children || []).filter(this.isEdgeSupportedCell)
                : this.isEdgeSupportedCell(source)
                ? [source]
                : [];

            if (!sourceCell) {
                return;
            }

            this.clearEdges(sourceCell);

            const targetGridCell: GridCell | undefined = cells.find((c) => {
                return c.id === sourceCell.parent.getId();
            });

            if (!targetGridCell) {
                return;
            }

            type TConnectFn = {
                sourceCell: MxCell;
                localTargetGridCell?: GridCell | undefined;
                reverseDirection?: boolean;
            };

            const connectRow = ({
                sourceCell,
                localTargetGridCell = undefined,
                reverseDirection = false,
            }: TConnectFn) => {
                const firstGridTargetCell =
                    localTargetGridCell ||
                    byRowId[targetGridCell.rowId].find((r) => {
                        return r.columnId === firstColumnId;
                    });

                const firstTargetCell = model.getCell(firstGridTargetCell?.id);

                if (firstTargetCell?.children?.length && sourceCell) {
                    this.connectTableCellChildren({
                        sourceCell,
                        targetGridCell: firstGridTargetCell,
                        title: messages.referTo,
                        getEdgeTypeId: () => PSDEdgeTypeId.BELONG,
                        reverseDirection,
                    });
                }
            };

            const connectColumn = ({ sourceCell, localTargetGridCell, reverseDirection = true }: TConnectFn) => {
                const gridTargetCell =
                    localTargetGridCell ||
                    byColumnId[targetGridCell.columnId].find((cell) => {
                        return cell.rowId === firstRowId;
                    });

                const firstTargetCell = model.getCell(gridTargetCell?.id);

                if (firstTargetCell?.children?.length && sourceCell) {
                    this.connectTableCellChildren({
                        sourceCell,
                        targetGridCell: gridTargetCell,
                        title: messages.consistOf,
                        getEdgeTypeId: () => PSDEdgeTypeId.CONSIST,
                        style: ';horizontal=0;',
                        reverseDirection,
                    });
                }
            };

            if (targetGridCell?.rowId === firstRowId) {
                const columnGridCells = byColumnId[targetGridCell.columnId];
                columnGridCells.forEach((t) => {
                    if (t.rowId === firstRowId) {
                        return;
                    }

                    connectColumn({ sourceCell, localTargetGridCell: t, reverseDirection: true });
                });

                return;
            }

            if (targetGridCell?.columnId === firstColumnId) {
                const rowGridCells = byRowId[targetGridCell.rowId];
                rowGridCells.forEach((t) => {
                    if (t.columnId === firstColumnId) {
                        return;
                    }

                    connectRow({ sourceCell, localTargetGridCell: t, reverseDirection: true });
                });

                return;
            }

            connectRow({ sourceCell });
            connectColumn({ sourceCell });
        } catch (e) {
            console.error(e);
        } finally {
            this.graph.refresh();
        }
    }

    getNewColumnTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnConsistOf);
    }

    getNewRowTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newRowReferTo);
    }
}

export default PSDGrid;
